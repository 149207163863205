<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-profile-edit"
      ref="modal"
      title="Edytuj dane kontaktowe"
      ok-title="Zaktualizuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @cancel="resetForm"
      @ok="handleModalOk"
      @shown="fillModal"
    >
      <b-form novalidate>
        <b-form-group
          label="pesel"
          label-for="pesel"
          :state="null"
        >
          <b-form-input id="input" v-model="form.pesel"></b-form-input>
        </b-form-group>

        <b-form-group
          label="first_name"
          label-for="first_name"
          :state="null"
        >
          <b-form-input id="input" v-model="form.first_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="last_name"
          label-for="last_name"
          :state="null"
        >
          <b-form-input id="input" v-model="form.last_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="second_name"
          label-for="second_name"
          :state="null"
        >
          <b-form-input id="input" v-model="form.second_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="birth_date"
          label-for="birth_date"
          :state="null"
        >
          <b-form-input id="input" v-model="form.birth_date"></b-form-input>
        </b-form-group>

        <b-form-group
          label="sex"
          label-for="sex"
          :state="null"
        >
          <b-form-input id="input" v-model="form.sex"></b-form-input>
        </b-form-group>

        <b-form-group
          label="birth_place"
          label-for="birth_place"
          :state="null"
        >
          <b-form-input id="input" v-model="form.birth_place"></b-form-input>
        </b-form-group>

        <b-form-group
          label="citizenship"
          label-for="citizenship"
          :state="null"
        >
          <b-form-input id="input" v-model="form.citizenship"></b-form-input>
        </b-form-group>

        <b-form-group
          label="father_name"
          label-for="father_name"
          :state="null"
        >
          <b-form-input id="input" v-model="form.father_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="mother_name"
          label-for="mother_name"
          :state="null"
        >
          <b-form-input id="input" v-model="form.mother_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="document"
          label-for="document"
          :state="null"
        >
          <b-form-input id="input" v-model="form.document"></b-form-input>
        </b-form-group>

        <b-form-group
          label="document_number"
          label-for="document_number"
          :state="null"
        >
          <b-form-input id="input" v-model="form.document_number"></b-form-input>
        </b-form-group>

        <b-form-group
          label="city"
          label-for="city"
          :state="null"
        >
          <b-form-input id="input" v-model="form.city"></b-form-input>
        </b-form-group>

        <b-form-group
          label="postcode"
          label-for="postcode"
          :state="null"
        >
          <b-form-input id="input" v-model="form.postcode"></b-form-input>
        </b-form-group>

        <b-form-group
          label="street"
          label-for="street"
          :state="null"
        >
          <b-form-input id="input" v-model="form.street"></b-form-input>
        </b-form-group>

        <b-form-group
          label="building_number"
          label-for="building_number"
          :state="null"
        >
          <b-form-input id="input" v-model="form.building_number"></b-form-input>
        </b-form-group>

        <b-form-group
          label="apartment_number"
          label-for="apartment_number"
          :state="null"
        >
          <b-form-input id="input" v-model="form.apartment_number"></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { baseURL } from "@/core/services/api.service.js";
import {mapGetters} from "vuex";

export default {
  name: "ModalProfileEdit",
  props: ["id", "uuid"],
  data() {
    return {
      insurer: null,
      form: {
        formState: null,
        pesel: "81060504222",
        first_name: "Ewa",
        last_name: "Adaszyńska",
        second_name: "",
        birth_date: "05-06-1981",
        sex: "Kobieta",
        birth_place: "Brzeg",
        citizenship: "polskie",
        father_name: "Ryszard",
        mother_name: "Janina",
        document: "dowód osobisty",
        document_number: "ATP348978",
        city: "Wrocław",
        postcode: "53-004",
        street: "Kawalerzystów",
        building_number: "33",
        apartment_number: "7"
      }
    };
  },
  computed: {
    ...mapGetters(["insurers"]),
    getBaseURL() {
      return baseURL;
    }
  },
  mounted() {},
  methods: {
    resetForm() {
      this.form.pesel = "81060504222";
      this.form.first_name = "Ewa";
      this.form.last_name = "Adaszyńska";
      this.form.second_name = "";
      this.form.birth_date = "05-06-1981";
      this.form.sex = "Kobieta";
      this.form.birth_place = "Brzeg";
      this.form.citizenship = "polskie";
      this.form.father_name = "Ryszard";
      this.form.mother_name = "Janina";
      this.form.document = "dowód osobisty";
      this.form.document_number = "ATP348978";
      this.form.city = "Wrocław";
      this.form.postcode = "53-004";
      this.form.street = "Kawalerzystów";
      this.form.building_number = "33";
      this.form.apartment_number = "7";
    },
    validateForm() {
      this.form.formState = true;
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.form.formState) {
        // let formToSend = this.form;
        // this.$store.dispatch(INSURER_EDIT, {
        //   uuid: this.uuid,
        //   ...formToSend
        // });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-profile-edit");
        });
      }
    },
    fillModal() {

    }
  }
};
</script>
