<template>
  <div class="container px-0 h-100">
    <div class="wide-box-full">
      <div class="card border-radius-1 mb-4 pb-4">
        <div
          class="card-header-profile border-bottom d-flex align-items-end pb-4"
        >
          <div class="card-title-profile flex-grow-1">
            <div class="d-flex flex-row">
              <div class="pe-2">
                <img src="/media/brand/profile-avatar.png" alt="" />
              </div>
              <div class="pe-5 py-4 profile-fullname align-middle">
                Izabela<br />
                Słowikowska
              </div>
              <div class="pe-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">113 zł</h3>
                  <span class="sub-title p-1">Prowizja 09/21</span>
                </div>
              </div>
              <div class="pe-4">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">10 polis</h3>
                  <span class="sub-title p-1">Wznowienia 09/21</span>
                </div>
              </div>
              <div class="pe-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">30 polis</h3>
                  <span class="sub-title p-1">Sprzedaż 08/21</span>
                </div>
              </div>
              <div class="pe-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">525 zł</h3>
                  <span class="sub-title p-1">Prowizja 08/21</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-tabs content-class="mt-3" fill>
            <b-tab title="Konto" active>
              <div class="row">
                <div class="col-6">
                  <div class="card border-right-1 ">
                    <div class="card-header d-flex align-items-end">
                      <div class="card-title flex-grow-1">
                        <h3 class="text-secondary">Dane kontaktowe</h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row py-2">
                        <div class="col-4">Telefon firmowy</div>
                        <div class="col-8">505 403 961</div>
                      </div>
                      <div class="row py-2">
                        <div class="col-4">E-mail firmowy</div>
                        <div class="col-8">ewa.adaszynska@openbrokers.pl</div>
                      </div>
                      <div class="row py-2">
                        <div class="col-4">Telefon prywatny</div>
                        <div class="col-8">600 752 536</div>
                      </div>
                      <div class="row py-2">
                        <div class="col-4">E-mail prywatny</div>
                        <div class="col-8">-----</div>
                      </div>
                      <div class="row pt-2 pb-4">
                        <div class="col-4">Oddział</div>
                        <div class="col-8">Wrocław 01 - Gwiaździsta 66</div>
                      </div>
                    </div>
                    <div class="card-footer p-0 border-0 bg-transparent">
                      <div class="row">
                        <div class="col-4">
                          <button type="button" class="btn btn-primary">
                            Edytuj
                          </button>
                        </div>
                        <div class="col-8">
                          <button type="button" class="btn btn-primary">
                            Zmień hasło
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card border-radius-1">
                    <div class="card-header d-flex align-items-end">
                      <div class="card-title flex-grow-1">
                        <h3 class="text-secondary">
                          Ustawienia ekranu głównego
                        </h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox
                          v-for="option in options"
                          :key="option.value"
                          v-model="selected"
                          :value="option.value"
                          :aria-describedby="ariaDescribedby"
                          name="flavour-3a"
                          class="label-cb py-2"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div class="card-footer p-0 border-0 bg-transparent">
                      <button type="button" class="btn btn-primary">
                        Zapisz
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Polisy" disabled><p>I'm the second tab</p></b-tab>
            <b-tab title="Wznowienia" disabled
              ><p>I'm a disabled tab!</p></b-tab
            >
            <b-tab title="Sprawy" disabled><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Wznowienia" disabled
              ><p>I'm a disabled tab!</p></b-tab
            >
            <b-tab title="Leady" disabled><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Rozliczenia" disabled
              ><p>I'm a disabled tab!</p></b-tab
            >
            <b-tab title="Kalendarz" disabled><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Szkolenia" disabled><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Uprawnienia" disabled
              ><p>I'm a disabled tab!</p></b-tab
            >
            <b-tab title="RODO/IDD" disabled><p>I'm a disabled tab!</p></b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="card border-radius-1 mt-4 pt-4">
        <div class="card-header d-flex align-items-end">
          <div class="card-title flex-grow-1">
            <h3 class="text-secondary">Systemy zewnętrzne TU</h3>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-custom-1">
            <thead>
              <tr>
                <th style="min-width: 160px">Serwis</th>
                <th style="min-width: 120px">Ubezpieczyciel</th>
                <th style="min-width: 120px">
                  Status
                </th>
                <th style="min-width: 100px">Dodatkowe informacje</th>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(extSys, index) in externalSystems" :key="index">
                <td style="min-width: 160px">
                  {{ extSys.serwis }}
                </td>
                <td style="min-width: 120px">
                  {{ extSys.insurer }}
                </td>
                <td style="min-width: 120px">
                  {{ extSys.status }}
                </td>
                <td style="min-width: 100px">{{ extSys.notice }}</td>
                <td style="min-width: 100px">
                  <button
                    v-if="index === 1 || index === 2"
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                  >
                    Konfiguruj
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card border-radius-1 mt-4 pt-4">
        <div class="card-header d-flex align-items-end">
          <div class="card-title flex-grow-1">
            <h3 class="text-secondary">Dane kontaktowe</h3>
          </div>
          <div
            class="card-header-tools"
            style="margin-bottom: 0px; margin-right: 0px;"
          >
            <a v-b-modal.modal-profile-edit class="">
              <button type="button" class="btn btn-sm btn-primary">
                Edytuj
              </button>
            </a>
          </div>
          <ModalProfileEdit />
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="row py-2">
                <div class="col-4">PESEL</div>
                <div class="col-8">81060504222</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Imię</div>
                <div class="col-8">Ewa</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Nazwisko</div>
                <div class="col-8">Adaszyńska</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Drugie imie</div>
                <div class="col-8">-----</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Data urodzenia</div>
                <div class="col-8">05-06-1981</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Płeć</div>
                <div class="col-8">Kobieta</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Miejsce urodzenia</div>
                <div class="col-8">Brzeg</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Obywatelstwo</div>
                <div class="col-8">polskie</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Imię ojca</div>
                <div class="col-8">Ryszard</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Imię matki</div>
                <div class="col-8">Janina</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Dokument</div>
                <div class="col-8">dowód osobisty</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Numer dokumentu</div>
                <div class="col-8">ATP348978</div>
              </div>
            </div>
            <div class="col-6">
              <div class="row py-2">
                <div class="col-4"><h3 class="small text-secondary">Adres zameldowania</h3></div>
              </div>
              <div class="row py-2">
                <div class="col-4">Miejscowość</div>
                <div class="col-8">Wrocław</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Kod pocztowy</div>
                <div class="col-8">53-004</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Ulica</div>
                <div class="col-8">Kawalerzystów</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Nr domu/lokalu</div>
                <div class="col-8">33/7</div>
              </div>
              <div class="row py-2">
                <div class="col-8"><hr/></div>
                <div class="col-4"></div>
              </div>
              <div class="row py-2">
                <div class="col-4"><h3 class="small text-secondary">Adres korespondencyjny</h3></div>
              </div>
              <div class="row py-2">
                <div class="col-4">Miejscowość</div>
                <div class="col-8">Wrocław</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Kod pocztowy</div>
                <div class="col-8">53-413</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Ulica</div>
                <div class="col-8">Gwiaździsta</div>
              </div>
              <div class="row py-2">
                <div class="col-4">Nr domu/lokalu</div>
                <div class="col-8">66</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalProfileEdit from "@/view/content/components/modal/ModalProfileEdit";

export default {
  name: "Profile",
  components: { ModalProfileEdit },
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "Koniec badań technicznych", value: "v1" },
        { text: "Urodziny klientów", value: "v2" },
        { text: "Polisy wymagające akcji", value: "v3" },
        { text: "Rozliczenia", value: "v4" },
        { text: "Polisy wystawione", value: "v5" }
      ],
      isEditingShortcuts: false,
      shortcuts: [],
      shortcutsAvailable: [],
      externalSystems: [
        {
          id: 0,
          serwis: "Jupiter",
          insurer: "Ergo Hestia/MTU",
          status: "OK",
          notice: ""
        },
        {
          id: 1,
          serwis: "Merkury",
          insurer: "Generali/Proama",
          status: "Brak konfiguracji",
          notice: "System nie jest skonfigurowany"
        },
        {
          id: 2,
          serwis: "LINK4 - strefa agenta",
          insurer: "LINK4",
          status: "brak konfiguracji",
          notice: "System nie jest skonfigurowany"
        },
        {
          id: 2,
          serwis: "Everest",
          insurer: "PZU",
          status: "OK",
          notice: ""
        }
      ],
      initialShortcuts: [
        {
          id: 0,
          name: "Rejestruj polisę",
          order: 1,
          url: "register-policy"
        },
        {
          id: 1,
          name: "Utwórz sprawę",
          order: 2,
          url: ""
        },
        {
          id: 2,
          name: "Moja agencja",
          order: 3,
          url: ""
        },
        {
          id: 3,
          name: "Kontakty",
          order: 4,
          url: ""
        }
      ],
      initialShortcutsAvailable: [
        {
          id: 0,
          name: "Moje polisy",
          order: 1,
          url: ""
        },
        {
          id: 1,
          name: "Moje sprawy",
          order: 2,
          url: ""
        },
        {
          id: 2,
          name: "Wznowienia",
          order: 3,
          url: ""
        },
        {
          id: 3,
          name: "Upoważnienia",
          order: 4,
          url: ""
        },
        {
          id: 4,
          name: "Szkolenia",
          order: 5,
          url: ""
        },
        {
          id: 5,
          name: "Rozliczenia",
          order: 6,
          url: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["renewals"])
  },
  mounted() {
    this.setupShortcuts();
  },
  methods: {
    handleAddShortcut(id) {
      const find = this.shortcutsAvailable.find(o => o.id === id);
      this.shortcuts.push(find);
      localStorage.setItem("shortcuts", JSON.stringify(this.shortcuts));

      const index = this.shortcutsAvailable.findIndex(o => o.id === id);
      if (index !== -1) {
        this.shortcutsAvailable.splice(index, 1);
      }
      localStorage.setItem(
        "shortcutsAvailable",
        JSON.stringify(this.shortcutsAvailable)
      );
    },
    handleRemoveShortcut(id) {
      const find = this.shortcuts.find(o => o.id === id);
      this.shortcutsAvailable.push(find);
      localStorage.setItem(
        "shortcutsAvailable",
        JSON.stringify(this.shortcutsAvailable)
      );

      const index = this.shortcuts.findIndex(o => o.id === id);
      if (index !== -1) {
        this.shortcuts.splice(index, 1);
      }
      localStorage.setItem("shortcuts", JSON.stringify(this.shortcuts));
    },
    getRenewalDaysColor(days) {
      if (days > 14) {
        return "#0A920A";
      } else if (days <= 14 && days >= 2) {
        return "#E12424";
      } else if (days <= 2) {
        return "#920A0A";
      }
    },
    setupShortcuts() {
      const shortcuts = localStorage.getItem("shortcuts")
        ? JSON.parse(localStorage.getItem("shortcuts"))
        : null;
      if (shortcuts === null) {
        localStorage.setItem(
          "shortcuts",
          JSON.stringify(this.initialShortcuts)
        );
        this.shortcuts = this.initialShortcuts;
      } else {
        this.shortcuts = shortcuts;
      }

      const shortcutsAvailable = localStorage.getItem("shortcutsAvailable")
        ? JSON.parse(localStorage.getItem("shortcutsAvailable"))
        : null;

      if (shortcutsAvailable === null) {
        localStorage.setItem(
          "shortcutsAvailable",
          JSON.stringify(this.initialShortcutsAvailable)
        );
        this.shortcutsAvailable = this.initialShortcutsAvailable;
      } else {
        this.shortcutsAvailable = shortcutsAvailable;
      }
    }
  }
};
</script>
